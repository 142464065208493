import { useEffect, useState } from 'react';
import { useApolloClient } from '@apollo/client';
import { Button, Form, Input } from 'reactstrap';
import { errorMsg, successMsg } from '../../cache/vars';
import { DECLINE_SERVICE, GET_AVAILABLE_FILTERS_FOR_PACKAGE_INFO, SAVE_STAGE_ONE_CARD } from '../../queries/vip-workflow';
import { SERVICE_STATE } from '../../utils/constants';
import StagedCardLayout from '../StagedCard/StagedCardLayout/StagedCardLayout';
import CardDetails from '../StagedCard/CardDetails/CardDetails';
import CardAction from '../StagedCard/CardAction/CardAction';
import reservationIcon from '../../assets/img/icons/cards/reservation-icon.svg';
import ticketIcon from "../../assets/img/icons/common/ticket-icon.svg";
import flagIcon from '../../assets/img/icons/cards/flag-icon.svg';
import buildingIcon from "../../assets/img/icons/cards/building-icon.svg";
import helpIcon from '../../assets/img/icons/cards/help-icon.svg';
import CardDeclineBtn from '../StagedCard/CardDeclineBtn/CardDeclineBtn';
import CardGuaranteed from '../StagedCard/CardGuaranteed/CardGuaranteed';
import { generateMailtoLink } from '../../utils/services';
import GetHelpModal from '../Modal/GetHelpModal';
import { TicketActions } from '../Modal/TicketActions';
import { TicketsPreviewModal } from '../Modal/TicketsPreviewModal';

import './cityPassGroupCardNew.scss';

type CityPassGroupCardNewProps = {
  cart: any;
  datetime: any | null;
  isMissingInfo: boolean;
  isPublished: boolean;
  isServiceDeclined: boolean;
  serviceDeclined: string;
  packageDetailsId: number;
  setPackageOrderWithDetails: (data: any) => void;
  decryptedInvoiceId: any;
  setMissingInfoCards: any;
  cardDescription: string;
  cardGroup: string;
  attendeeQty: number;
  cardsWithTickets: Record<string, any>;
  ticketsForCard: Record<string, any>;
};

const CityPassGroupCardNew = ({
  cart,
  datetime,
  isMissingInfo,
  isPublished,
  isServiceDeclined,
  serviceDeclined,
  packageDetailsId,
  setPackageOrderWithDetails,
  decryptedInvoiceId,
  setMissingInfoCards,
  attendeeQty,
  cardGroup,
  cardsWithTickets,
  ticketsForCard,
}: CityPassGroupCardNewProps) => {
  const client = useApolloClient();
  
  const [file, setFile] = useState<string | undefined>("");
  const [fileType, setFileType] = useState<string>("pdf");
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [isModalOpened, setIsModalOpened] = useState(false);
  const [selectedFile, setSelectedFile] = useState<string | undefined>("");
  const [emailLink, setEmailLink] = useState("");
  const [isValid, setIsValid] = useState<boolean>(true);
  const [isFieldsValid, setIsFieldsValid] = useState<number[]>([]);
  const [isSubmitBtnDisabled, setIsSubmitBtnDisabled] = useState(true);

  const [cardState, setCardState] = useState<{ packageDetailsId: number; cartData: any }>({
    packageDetailsId,
    cartData: { attendeeNames: [...new Array(attendeeQty).fill({ attendeeName: "" })] },
  });

  const handleDeclineClick = async () => {
    if (window.confirm("Are you sure you want to decline this service?")) {
      const { data } = await client.mutate({
        mutation: DECLINE_SERVICE,
        variables: { packageDetailsId: packageDetailsId },
      });

      if (data?.declineService) {
        setPackageOrderWithDetails((prev: any) => {
          return {
            ...prev,
            orderDetails: prev.orderDetails.map((item: any) => {
              return item.packageDetailsId === packageDetailsId
                ? {
                    ...item,
                    cartDetails: {
                      ...item.cartDetails,
                      cartId: data?.declineService,
                      isServiceDeclined: SERVICE_STATE.DECLINED,
                    },
                  }
                : item;
            }),
          };
        });
        successMsg("Service Declined");
      }
    }
  };
  
  useEffect(() => {
    const emptyRequiredFieldsIds = cardState.cartData.attendeeNames.reduce(
      (prev: any, el: { attendeeName: string }, id: number) => {
        if (!el.attendeeName || !/^\s*\S+\s+\S+.*$/.test(el.attendeeName)) {
          prev.push(id);
        }
        return prev;
      },
      [],
    );
    
    setIsFieldsValid(emptyRequiredFieldsIds);
    setIsSubmitBtnDisabled(!!emptyRequiredFieldsIds?.length);
  }, [cardState.cartData]);
  
  useEffect(() => {
    generateMailtoLink(client, window.location.pathname, setEmailLink);
  }, [client]);
  
  const cartJsonData = JSON.parse(cart.cartDetails.cartJsonData);
  
  const cardTicket = cardsWithTickets?.find((card: any) => card.packageDetailsId === packageDetailsId);

  return (
    <StagedCardLayout
      cardIcon={buildingIcon}
      cardTitle={cart?.cartDetails?.cardTitle || "City Pass Details"}
      datetime={datetime}
      isPublished={isPublished}
      isServiceDeclined={isServiceDeclined}
      serviceDeclined={serviceDeclined}
      isMissingInfo={isMissingInfo}
      cardGroup={cardGroup}
    >
      <>
        <GetHelpModal isModalOpen={isModalOpen} setIsModalOpen={setIsModalOpen} tag={cardGroup.replaceAll(' ', '')} />
        
        {isPublished &&
          <>
            <CardDetails
              cardIcon={reservationIcon}
              cardTitle="City Pass Details"
              cardSubtitle={
              <span
                style={{ whiteSpace: 'pre-line' }}
                dangerouslySetInnerHTML={{
                  __html: cartJsonData?.description,
                }}>
              </span>}
            />
            
            {cardTicket &&
              <>
                <div className="divider" />
                <CardDetails
                  cardIcon={ticketIcon}
                  cardTitle="Access Tickets now"
                  children={
                    <div className="mt-4">
                      {ticketsForCard && ticketsForCard.length ? (
                        ticketsForCard.map((ticket: any, index: number) => (
                          <TicketActions
                            ticket={ticket}
                            setFile={setFile}
                            setIsModalOpened={setIsModalOpened}
                            setSelectedFile={setSelectedFile}
                          />
                        ))
                      ) : (
                        <p>No tickets available</p>
                      )}
                      
                      <div className="divider" />
                    </div>
                  }
                />
              </>
            }
            
            <div className="divider" />

            {cartJsonData?.notes &&
              <>
                <CardDetails
                  cardIcon={flagIcon}
                  cardTitle="Additional Notes"
                  cardSubtitle={cartJsonData?.notes}
                />
                
                <div className="divider" />
              </>
            }
            
            <CardAction icon={helpIcon} text="Get help" 
              handleClick={() => {
                setIsModalOpen(true)
              }}
            />
          </>
        }

        {!isPublished && isMissingInfo && (
          <>
            <CardDetails
              isEditable={true}
              cardIcon={reservationIcon}
              cardTitle="City Pass Details"
              cardSubtitle="Please provide your contact info"
            >
              <>
                <div className="divider"></div>
                <Form
                  onSubmit={async (e) => {
                    e.preventDefault();

                    const emptyRequiredFieldsIds = cardState.cartData.attendeeNames.reduce(
                      (prev: any, el: { attendeeName: string }, id: number) => {
                        if (!el.attendeeName) {
                          prev.push(id);
                        }

                        return prev;
                      },
                      [],
                    );

                    if (emptyRequiredFieldsIds.length) {
                      setIsFieldsValid((prev: any) => {
                        return [...emptyRequiredFieldsIds];
                      });
                      errorMsg("Required Fields Should Not Be Empty");
                      return;
                    }

                    const { data } = await client.mutate({
                      mutation: SAVE_STAGE_ONE_CARD,
                      variables: { editCartDetailsInput: cardState },
                    });

                    const filtersData = await client.query({
                      query: GET_AVAILABLE_FILTERS_FOR_PACKAGE_INFO,
                      variables: { invoiceId: decryptedInvoiceId },
                      fetchPolicy: "no-cache",
                    });

                    if (data) {
                      setPackageOrderWithDetails((prev: any) => {
                        return {
                          ...prev,
                          orderDetails: [
                            ...prev.orderDetails.map((cart: any) => {
                              return cart.packageDetailsId === packageDetailsId
                                ? {
                                    ...cart,
                                    cartDetails: {
                                      ...cart.cartDetails,
                                      cardTitle: data?.saveStageOneCard.cardTitle,
                                      cartId: data?.saveStageOneCard.cartId,
                                      cartJsonData: data?.saveStageOneCard.cartJsonData,
                                      isPublished: data?.saveStageOneCard.isPublished,
                                      packageDetailsId: data?.saveStageOneCard.packageDetailsId,
                                    },
                                  }
                                : cart;
                            }),
                          ],
                        };
                      });

                      setMissingInfoCards(
                        filtersData.data?.getAvailableFiltersForPackageInfo.showMissingInfoFor,
                      );

                      successMsg("Saved");
                    }
                  }}
                >
                  <div className="inputs-container">
                    <p style={{ fontSize: '20px', fontWeight: '400', fontFamily: "'Inter', sans-serif" }}>Contact Details</p>
                    <p style={{ fontSize: '14px', fontWeight: '400', fontFamily: "'Inter', sans-serif", marginTop: '-1rem', color: '#D13053' }}>Attendee Full Name Required and Must Match Photo ID</p>
                    
                    {!isValid && (
                      <>
                        <p className="required-note" style={{ fontSize: '14px', fontWeight: '400', fontFamily: "'Inter', sans-serif", marginTop: '0.5rem' }}>
                          *First and last name are required
                        </p>
                        <span style={{ fontSize: '12px', fontWeight: '400', fontFamily: "'Inter', sans-serif", color: '#5F6778', marginTop: '-1rem', display: 'block' }}>
                          Numbers and special symbols (except dot, hyphen, and apostrophe) are not allowed.
                        </span>
                      </>)}
                    
                    {cardState.cartData.attendeeNames.map((item: { attendeeName: string }, id: number) => {
                      return (
                        <>
                          
                          <div key={id} className="input-container">
                            <div className="input-box">
                              <Input
                                className={isFieldsValid.includes(id) && item?.attendeeName?.length ? "input-invalid" : ""}
                                style={{marginTop: '0'}}
                                type="text"
                                placeholder="Attendee First Name and Last Name"
                                name="attendeeName"
                                invalid={isFieldsValid.includes(id)}
                                value={item.attendeeName}
                                onChange={(e) => {
                                  const value = e.target.value;
                                  const regex = /^[a-zA-Z.\-'\s]*$/;
                                
                                  if (regex.test(value)) {
                                    setIsValid(!isSubmitBtnDisabled);
                                    setCardState((prev) => ({
                                      ...prev,
                                      cartData: {
                                        ...prev.cartData,
                                        attendeeNames: prev.cartData.attendeeNames.map((attendee: any, index: number) =>
                                          index === id ? { ...attendee, attendeeName: value } : attendee
                                        ),
                                      },
                                    }));
                                  }
                                }}                                
                              />
                            </div>
                          </div>
                        </>
                      );
                    })}
                  </div>
                  <div className="actions-container">
                    <CardDeclineBtn visible={!isServiceDeclined} handleClick={handleDeclineClick} />
                    <Button disabled={isSubmitBtnDisabled} className="btn-round btn-icon d-flex align-items-center save-btn" type="submit">
                      Save
                    </Button>
                  </div>
                </Form>
              </>
            </CardDetails>
            <div className="divider"></div>
            <CardAction icon={helpIcon} text="Get help" 
              handleClick={() => {
                setIsModalOpen(true)
              }}
            />
          </>
        )}
        {!isPublished && !isMissingInfo &&
          <>
            <CardDetails
              isToggleExpanded={false}
              cardIcon={reservationIcon}
              cardTitle="City Pass Details"
              cardSubtitle="Your city pass are guaranteed."
            >
              <div className="pl-4 mb-4">
                <CardGuaranteed text={isServiceDeclined && serviceDeclined !== 'UNDECLINED' ? "You’ve declined this service." : "Your city pass are guaranteed."} subtext={isServiceDeclined ? "If you need further assistance please use the Get Help button." : ""} />
                {!isServiceDeclined &&
                  <div className="actions-container">
                    <CardDeclineBtn visible={isServiceDeclined} handleClick={handleDeclineClick} />
                  </div>
                }
                <div className="divider"></div> 
                <CardAction icon={helpIcon} text="Get help" 
                  handleClick={() => {
                    setIsModalOpen(true)
                  }}
                />
              </div>
            </CardDetails>
          
            {cartJsonData?.notes &&
              <CardDetails
                cardIcon={flagIcon}
                cardTitle="Additional Notes"
                cardDescription={cartJsonData.notes}
              />
            }
          </>
        }
        
        <TicketsPreviewModal
          isModalOpened={isModalOpened}
          setIsModalOpened={setIsModalOpened}
          selectedFile={selectedFile}
          fileType={fileType}
        />
      </>
    </StagedCardLayout>
  );
};

export default CityPassGroupCardNew;
