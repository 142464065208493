import { useEffect, useState } from 'react';
import { useApolloClient } from '@apollo/client';
import { Button, Form, Input } from 'reactstrap';
import moment from 'moment';
import { errorMsg, successMsg } from '../../cache/vars';
import { DECLINE_SERVICE, GET_AVAILABLE_FILTERS_FOR_PACKAGE_INFO, SAVE_STAGE_ONE_CARD } from '../../queries/vip-workflow';
import { SERVICE_STATE } from '../../utils/constants';
import StagedCardLayout from '../StagedCard/StagedCardLayout/StagedCardLayout';
import CardDetails from '../StagedCard/CardDetails/CardDetails';
import GooglePlaceAutocompleteInput from '../GooglePlaceAutocomplete/GooglePlaceAutocomplete';
import TransferTypeButtons from '../Buttons/TransferTypeButtons';
import CardGoogleMap from '../StagedCard/CardGoogleMap/CardGoogleMap';
import CardGuaranteed from '../StagedCard/CardGuaranteed/CardGuaranteed';
import CardAction from '../StagedCard/CardAction/CardAction';
import CardDeclineBtn from '../StagedCard/CardDeclineBtn/CardDeclineBtn';
import FlightInfoPopover from '../Popovers/FlightInfoPopover';
import reservationIcon from '../../assets/img/icons/cards/reservation-icon.svg';
import ticketIcon from "../../assets/img/icons/common/ticket-icon.svg";
import flagIcon from '../../assets/img/icons/cards/flag-icon.svg';
import mapIcon from '../../assets/img/icons/cards/map-icon.svg';
import airportIcon from '../../assets/img/icons/cards/airport-icon.svg';
import jumpIcon from '../../assets/img/icons/cards/jump-icon.svg';
import planeIcon from '../../assets/img/icons/cards/plane-icon.svg';
import planeUpIcon from '../../assets/img/icons/cards/plane-up-icon.svg';
import helpIcon from '../../assets/img/icons/cards/help-icon.svg';
import carIcon from '../../assets/img/icons/cards/car-icon.svg';
import { generateMailtoLink } from '../../utils/services';
import GetHelpModal from '../Modal/GetHelpModal';
import { TicketActions } from '../Modal/TicketActions';
import { TicketsPreviewModal } from '../Modal/TicketsPreviewModal';

import './airportTransferGroupCardNew.scss';
import { PhoneInput } from '../Inputs/PhoneInput';

type TransferType = "transfer1" | "transfer2";

type AirportTransferGroupCardNewProps = { 
  cart: any;
  datetime: any | null;
  time: string;
  isMissingInfo: boolean;
  isPublished: boolean;
  isServiceDeclined: boolean;
  serviceDeclined: string;
  packageDetailsId: number;
  decryptedInvoiceId: any;
  setMissingInfoCards: any;
  cardDescription: string;
  cardGroup: string;
  setPackageOrderWithDetails: (data: any) => void;
  cardsWithTickets: Record<string, any>;
  ticketsForCard: Record<string, any>;
};

const AirportTransferGroupCardNew = ({
  cart,
  datetime,
  time,
  isMissingInfo,
  isPublished,
  isServiceDeclined,
  serviceDeclined,
  packageDetailsId,
  setPackageOrderWithDetails,
  decryptedInvoiceId,
  setMissingInfoCards,
  cardDescription,
  cardGroup,
  cardsWithTickets,
  ticketsForCard,
}: AirportTransferGroupCardNewProps) => {
  const client = useApolloClient();
  
  const [file, setFile] = useState<string | undefined>("");
  const [fileType, setFileType] = useState<string>("pdf");
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [isModalOpened, setIsModalOpened] = useState(false);
  const [selectedFile, setSelectedFile] = useState<string | undefined>("");
  const [emailLink, setEmailLink] = useState("");
  const [airportTransferType, setAirportTransferType] = useState<TransferType>(
    "transfer1",
  );
  const [transferId, setTransferId] = useState<"1" | "2">("1");
  const [isTransfer, setIsTransfer] = useState(true);
  const [cartJsonData, setCartJsonData] = useState<any>({});
  const [isSubmitBtnDisabled, setIsSubmitBtnDisabled] = useState(true);
  const [cardState, setCardState] = useState<{ packageDetailsId: number; cartData: any }>({
    packageDetailsId,
    cartData: {
      transfer1: {
        transferId: "1",
      },
      transfer2: {
        transferId: "2",
      },
    },
  });

  const [isFieldsValid, setIsFieldsValid] = useState({
    transfer1: {
      contactName1: true,
      contactNumber1: true,
      arrivalTime: true,
      airportName1: true,
      arrivalFlightAirline: true,
      flightNumber: true,
      departureFlightDate: true,
    },
    transfer2: {
      contactName2: true,
      contactNumber2: true,
      departureFlightDate: true,
      airportName2: true,
      departureFlightAirline: true,
      departureFlightNumber: true,
    },
  });
  
  const handleDeclineClick = async () => {
    if (window.confirm("Are you sure you want to decline this service?")) {
      const { data } = await client.mutate({
        mutation: DECLINE_SERVICE,
        variables: { packageDetailsId: packageDetailsId },
      });

      if (data?.declineService) {
        setPackageOrderWithDetails((prev: any) => {
          return {
            ...prev,
            orderDetails: prev.orderDetails.map((item: any) => {
              return item.packageDetailsId === packageDetailsId
                ? {
                    ...item,
                    cartDetails: {
                      ...item.cartDetails,
                      cartId: data?.declineService,
                      isServiceDeclined: SERVICE_STATE.DECLINED,
                    },
                  }
                : item;
            }),
          };
        });
        successMsg("Service Declined");
      }
    }
  };
  
  const parsedCardData = JSON.parse(cart.cartDetails.cartJsonData);

  useEffect(() => {
    const airportTransfer1RequiredFields = [
      "contactName1",
      "contactNumber1",
      "arrivalTime",
      "airportName1",
      "arrivalFlightAirline",
      "flightNumber",
      "departureFlightDate",
    ];

    const airportTransfer2RequiredFields = [
      "contactName2",
      "contactNumber2",
      "departureFlightDate",
      "airportName2",
      "departureFlightAirline",
      "departureFlightNumber",
    ];

    const isTransfer1RequiredFieldsEmpty = airportTransfer1RequiredFields.some((el) => {
      return !cardState.cartData.transfer1[el];
    });

    const isTransfer2RequiredFieldsEmpty = airportTransfer2RequiredFields.some((el) => {
      return !cardState.cartData.transfer2[el];
    });

    setIsSubmitBtnDisabled(isTransfer1RequiredFieldsEmpty || isTransfer2RequiredFieldsEmpty);
  }, [cardState.cartData]);
  
  useEffect(() => {
    const data = JSON.parse(cart.cartDetails.cartJsonData);
    
    if (airportTransferType === 'transfer1') {
      setTransferId('1');
    } else if (airportTransferType === 'transfer2') {
      setTransferId('2');
    }
    
    if (data && !data[airportTransferType]) {
      setTransferId(data.transferId);
    }

    if (data && data[airportTransferType]) {
      setCartJsonData(data[airportTransferType]);
    } else {
      setCartJsonData(data);
      setIsTransfer(false);
    }
  }, [airportTransferType, cart.cartDetails.cartJsonData]);
  
  useEffect(() => {
    generateMailtoLink(client, window.location.pathname, setEmailLink);
  }, [client]);
  
  const cardTicket = cardsWithTickets?.find((card: any) => card.packageDetailsId === packageDetailsId);
  
  const isArrival = airportTransferType === "transfer1";
  const isDeparture = airportTransferType === "transfer2";
  
  return (
    <StagedCardLayout
      cardIcon={airportIcon} 
      cardTitle={cart?.cartDetails?.cardTitle || "Airport Transfer"}
      datetime={datetime}
      time={time}
      isPublished={isPublished} 
      isServiceDeclined={isServiceDeclined}
      serviceDeclined={serviceDeclined}
      isMissingInfo={isMissingInfo}
      cardGroup={cardGroup}
    >
      <>
        <GetHelpModal isModalOpen={isModalOpen} setIsModalOpen={setIsModalOpen} tag={cardGroup.replaceAll(' ', '')} />
        
        {isPublished &&
          <>
            {isTransfer &&
              <div className="mb-4"> 
                <TransferTypeButtons
                  airportTransferType={airportTransferType}
                  setAirportTransferType={setAirportTransferType}
                  transfer1Date={parsedCardData?.transfer1?.date1 || parsedCardData?.transfer1?.date || ''}
                  transfer2Date={parsedCardData?.transfer2?.date2 || parsedCardData?.transfer2?.date || ''}
                />
              </div>
            }
          
            <CardDetails
              cardIcon={carIcon}
              cardTitle="Transport Details"
              cardSubtitle={transferId === '1' ? cartJsonData?.vendorName1 : cartJsonData?.vendorName2}
            >
              {
              transferId === '1' && 
                <>
                  <div className="airport-transfer-card-content">
                    {cartJsonData?.vendorPhone1 &&
                      <>
                        <div className="card-content-column">
                          <p style={{ fontSize: '14px', fontWeight: '500', fontFamily: "'Inter', sans-serif" }}>Vendor Phone</p>
                        </div>
                        <div className="card-content-column">
                          <p style={{ fontSize: '14px', fontWeight: '400', fontFamily: "'Inter', sans-serif" }}>
                            <a href="tel:+">{cartJsonData?.vendorPhone1.startsWith('+1') ? cartJsonData?.vendorPhone1.slice(2).trim() : cartJsonData?.vendorPhone1.trim()}</a>
                          </p>
                        </div>
                      </>
                    }
                  </div>
                  <div className="airport-transfer-card-content">
                    {cartJsonData?.contactName1 &&
                      <>
                        <div className="card-content-column">
                          <p style={{ fontSize: '14px', fontWeight: '500', fontFamily: "'Inter', sans-serif" }}>Reserved for</p>
                        </div>
                        <div className="card-content-column">
                          <p style={{ fontSize: '14px', fontWeight: '400', fontFamily: "'Inter', sans-serif" }}>
                            {cartJsonData?.contactName1}
                          </p>
                        </div>
                      </>
                    }
                  </div>
                  <div className="airport-transfer-card-content">
                    {cartJsonData?.contactNumber1 &&
                      <>
                        <div className="card-content-column">
                          <p style={{ fontSize: '14px', fontWeight: '500', fontFamily: "'Inter', sans-serif" }}>
                            Contact Phone
                          </p>
                        </div>
                        <div className="card-content-column">
                          <p style={{ fontSize: '14px', fontWeight: '400', fontFamily: "'Inter', sans-serif" }}>
                            <a href="tel:+">{cartJsonData?.contactNumber1.startsWith('+1') ? cartJsonData?.contactNumber1.slice(2).trim() : cartJsonData?.contactNumber1.trim()}</a>
                          </p>
                        </div>
                      </>
                    }
                  </div>
                </>
              }
              
              {
              transferId === '2' && 
              <>
                <div className="airport-transfer-card-content">
                  {cartJsonData?.vendorPhone2 &&
                    <>
                      <div className="card-content-column">
                        <p style={{ fontSize: '14px', fontWeight: '500', fontFamily: "'Inter', sans-serif" }}>Vendor Phone</p>
                      </div>
                      <div className="card-content-column">
                        <p style={{ fontSize: '14px', fontWeight: '400', fontFamily: "'Inter', sans-serif" }}>
                          <a href="tel:+">{cartJsonData?.vendorPhone2.startsWith('+1') ? cartJsonData?.vendorPhone2.slice(2).trim() : cartJsonData?.vendorPhone2.trim()}</a>
                        </p>
                      </div>
                    </>
                  }
                </div>
                <div className="airport-transfer-card-content">
                  {cartJsonData?.contactName2 &&
                    <>
                      <div className="card-content-column">
                        <p style={{ fontSize: '14px', fontWeight: '500', fontFamily: "'Inter', sans-serif" }}>Reserved for</p>
                      </div>
                      <div className="card-content-column">
                        <p style={{ fontSize: '14px', fontWeight: '400', fontFamily: "'Inter', sans-serif" }}>{cartJsonData?.contactName2}</p>
                      </div>
                    </>
                  }
                </div>
                <div className="airport-transfer-card-content">
                  {cartJsonData?.contactNumber2 &&
                    <>
                      <div className="card-content-column">
                        <p style={{ fontSize: '14px', fontWeight: '500', fontFamily: "'Inter', sans-serif" }}>Contact Phone</p>
                      </div>
                      <div className="card-content-column">
                        <p style={{ fontSize: '14px', fontWeight: '400', fontFamily: "'Inter', sans-serif" }}>
                          <a href="tel:+">{cartJsonData?.contactNumber2.startsWith('+1') ? cartJsonData?.contactNumber2.slice(2).trim() : cartJsonData?.contactNumber2.trim()}</a>
                        </p>
                      </div>
                    </>
                  }
                </div>
              </>
              }
            </CardDetails>
            
            <div className="divider" />
            
            <CardDetails
              cardIcon={planeUpIcon}
              cardTitle="Pick Up At"
              cardSubtitleGoogleLink={{
                name: isArrival ? cartJsonData?.airportName1 : cartJsonData?.pickUpName || cartJsonData?.hotelName || '',
                address: isArrival
                  ? cartJsonData?.airportPlaceName
                  : cartJsonData?.pickUpPlaceName || cartJsonData?.hotelPlaceName || '',
                link: isArrival
                  ? cartJsonData?.airportPlaceLink
                  : cartJsonData?.pickUpPlaceLink || cartJsonData?.hotelPlaceLink || '',
                time: isArrival ? cartJsonData?.date1 : cartJsonData?.date2 || '',
              }}
            >
              <>
                {isArrival && (
                  <>
                    <div className="airport-transfer-card-content">
                      {cartJsonData?.confirmation1 && (
                        <>
                          <div className="card-content-column">
                            <p style={{ fontSize: '14px', fontWeight: '500', fontFamily: "'Inter', sans-serif" }}>Confirmation</p>
                          </div>
                          <div className="card-content-column">
                            <p style={{ fontSize: '14px', fontWeight: '400', fontFamily: "'Inter', sans-serif" }}>{cartJsonData?.confirmation1}</p>
                          </div>
                        </>
                      )}
                    </div>
                    <div className="airport-transfer-card-content">
                      {cartJsonData?.flightNumber && (
                        <>
                          <div className="card-content-column">
                            <p style={{ fontSize: '14px', fontWeight: '500', fontFamily: "'Inter', sans-serif" }}>
                              Flight Num. {cartJsonData?.flightNumber} Arriving
                            </p>
                          </div>
                          <div className="card-content-column">
                            <div>
                              <p style={{ fontSize: '14px', fontWeight: '400', fontFamily: "'Inter', sans-serif", display: 'inline' }}>
                                {moment(cartJsonData?.arrivalTime).format("h:mm a")} Flight Info
                              </p>
                              <span style={{ fontSize: '14px', fontWeight: '400', fontFamily: "'Inter', sans-serif" }}>
                                <FlightInfoPopover 
                                  parsedCardDetails={cartJsonData} 
                                  config={{ name: 'flightInfo' }} 
                                  cartInfoId={packageDetailsId} 
                                  cardGroup={cardGroup}
                                />
                              </span>
                            </div>
                          </div>
                        </>
                      )}
                    </div>
                    <CardGoogleMap address={cartJsonData?.dropOffPlaceName} className="pl-4" />
                  </>
                )}

                {isDeparture && (
                  <>
                    <div className="airport-transfer-card-content">
                      {cartJsonData?.confirmation2 && (
                        <>
                          <div className="card-content-column">
                            <p style={{ fontSize: '14px', fontWeight: '500', fontFamily: "'Inter', sans-serif" }}>Confirmation</p>
                          </div>
                          <div className="card-content-column">
                            <p style={{ fontSize: '14px', fontWeight: '400', fontFamily: "'Inter', sans-serif" }}>{cartJsonData?.confirmation2}</p>
                          </div>
                        </>
                      )}
                    </div>
                    <CardGoogleMap address={cartJsonData?.pickUpPlaceName || cartJsonData?.hotelPlaceName} className="pl-4" />
                  </>
                )}
              </>
            </CardDetails>
            
            <div className="divider" />
            
            <CardDetails
              cardIcon={mapIcon}
              cardTitle="Transporting To"
              cardSubtitleGoogleLink={{
                name: isArrival ? cartJsonData?.dropOffNamе : isDeparture ? cartJsonData?.airportName : '',
                address: isArrival 
                  ? cartJsonData?.dropOffPlaceName || cartJsonData?.hotelPlaceName || ''
                  : isDeparture
                  ? cartJsonData?.airportPlaceName
                  : '',
                link: isArrival 
                  ? cartJsonData?.dropOffPlaceLink || cartJsonData?.hotelPlaceLink || ''
                  : isDeparture
                  ? cartJsonData?.airportPlaceLink
                  : '',
                time: ''
              }}
            >
              <>
                {isArrival && (
                  <>
                    <CardGoogleMap
                      address={cartJsonData?.dropOffPlaceName || cartJsonData?.hotelPlaceName}
                      className="pl-4"
                    />
                  </>
                )}
                
                {isDeparture && (
                  <>
                    <div className="airport-transfer-card-content">
                      {cartJsonData?.departureFlightNumber && (
                        <>
                          <div className="card-content-column">
                            <div>
                              <p style={{ fontSize: '14px', fontWeight: '400', fontFamily: "'Inter', sans-serif", display: 'inline' }}>
                                Flight Num. {cartJsonData?.departureFlightNumber} <sup>*</sup> Departing
                              </p>
                              <span style={{ fontSize: '14px', fontWeight: '400', fontFamily: "'Inter', sans-serif" }}>
                                <FlightInfoPopover
                                  parsedCardDetails={cartJsonData}
                                  config={{ name: 'flightInfo' }}
                                  cartInfoId={packageDetailsId}
                                  cardGroup={cardGroup}
                                />
                              </span>
                            </div>
                          </div>
                          <div className="card-content-column">
                            <p>{moment(cartJsonData?.arrivalTime).format("h:mm a")}</p>
                          </div>
                        </>
                      )}
                    </div>
                    <CardGoogleMap address={cartJsonData?.airportPlaceName} className="pl-4" />
                  </>
                )}
              </>
            </CardDetails>
            
            {cardTicket &&
              <>
                <div className="divider" />
                <CardDetails
                  cardIcon={ticketIcon}
                  cardTitle="Access Tickets now"
                  children={
                    <div className="mt-4">
                      {ticketsForCard && ticketsForCard.length ? (
                        ticketsForCard.map((ticket: any, index: number) => (
                          <TicketActions
                            ticket={ticket}
                            setFile={setFile}
                            setIsModalOpened={setIsModalOpened}
                            setSelectedFile={setSelectedFile}
                          />
                        ))
                      ) : (
                        <p>No tickets available</p>
                      )}
                    </div>
                  }
                />
              </>
            }
            
            <div className="divider" />
            
            {cartJsonData?.notes1 &&
              <>
                <CardDetails
                  cardIcon={flagIcon}
                  cardTitle="Additional Notes"
                  cardDescription={transferId === '1' ? cartJsonData?.notes1 : cartJsonData?.notes2}
                />
                
                <div className="divider" />
              </>
            }
            
            {cartJsonData?.notes2 &&
              <>
                <CardDetails
                  cardIcon={flagIcon}
                  cardTitle="Additional Notes"
                  cardDescription={transferId === '1' ? cartJsonData?.notes1 : cartJsonData?.notes2}
                />
                
                <div className="divider" />
              </>
            }
            
            <CardAction icon={helpIcon} text="Get help" 
              handleClick={() => {
                setIsModalOpen(true)
              }}
            />
          </>
        }

        {!isPublished && isMissingInfo && (
          <>
            <div className="mb-4"> 
              <TransferTypeButtons
                airportTransferType={airportTransferType}
                setAirportTransferType={setAirportTransferType}
                transfer1Date={cartJsonData?.transfer1?.date1}
                transfer2Date={cartJsonData?.transfer2?.date2}
              />
            </div>

            <CardDetails
              isEditable={true}
              cardIcon={planeIcon}
              cardTitle="Arrival Details"
              cardSubtitle="Please provide your info"
            >
              <>
                <p className="mt-4 mr-4 mb-0 ml-2" style={{ fontSize: '14px', fontWeight: '400', fontFamily: "'Inter', sans-serif" }}>
                  Provide us with your arrival flight information. Transfers are reserved in a vehicle accommodating the size of your party associated to the package. Special vehicle types and additional stops will require an additional charge.
                </p>
                
                <div className="divider" />
                
                <div className="mb-4">
                  <p style={{ fontSize: '20px', fontWeight: '400', fontFamily: "'Inter', sans-serif" }}>Contact Details</p>
                  <p className="required-note" style={{ fontSize: '14px', fontWeight: '400', fontFamily: "'Inter', sans-serif" }}>* These fields are required.</p>
                </div>
                
                <Form
                  onSubmit={async (e) => {
                    e.preventDefault();
                    const airportTransfer1RequiredFields = [
                      "contactName1",
                      "contactNumber1",
                      "arrivalTime",
                      "airportName1",
                      "arrivalFlightAirline",
                      "flightNumber",
                      "departureFlightDate",
                    ];

                    const airportTransfer2RequiredFields = [
                      "contactName2",
                      "contactNumber2",
                      "departureFlightDate",
                      "airportName2",
                      "departureFlightAirline",
                      "departureFlightNumber",
                    ];

                    const isTransfer1RequiredFieldsEmpty = airportTransfer1RequiredFields.some((el) => {
                      return !cardState.cartData.transfer1[el];
                    });

                    const isTransfer2RequiredFieldsEmpty = airportTransfer2RequiredFields.some((el) => {
                      return !cardState.cartData.transfer2[el];
                    });

                    if (isTransfer1RequiredFieldsEmpty || isTransfer2RequiredFieldsEmpty) {
                      setIsFieldsValid({
                        transfer1: {
                          contactName1: !!cardState.cartData.transfer1.contactName1,
                          contactNumber1: !!cardState.cartData.transfer1.contactNumber1,
                          arrivalTime: !!cardState.cartData.transfer1.arrivalTime,
                          airportName1: !!cardState.cartData.transfer1.airportName1,
                          arrivalFlightAirline: !!cardState.cartData.transfer1.arrivalFlightAirline,
                          flightNumber: !!cardState.cartData.transfer1.flightNumber,
                          departureFlightDate: !!cardState.cartData.transfer1.departureFlightDate,
                        },
                        transfer2: {
                          contactName2: !!cardState.cartData.transfer2.contactName2,
                          contactNumber2: !!cardState.cartData.transfer2.contactNumber2,
                          departureFlightDate: !!cardState.cartData.transfer2.departureFlightDate,
                          airportName2: !!cardState.cartData.transfer2.airportName2,
                          departureFlightAirline: !!cardState.cartData.transfer2.departureFlightAirline,
                          departureFlightNumber: !!cardState.cartData.transfer2.departureFlightNumber,
                        },
                      });

                      errorMsg("Required Fields Should Not Be Empty");

                      return;
                    }

                    const { data } = await client.mutate({
                      mutation: SAVE_STAGE_ONE_CARD,
                      variables: { editCartDetailsInput: cardState },
                    });

                    const filtersData = await client.query({
                      query: GET_AVAILABLE_FILTERS_FOR_PACKAGE_INFO,
                      variables: { invoiceId: decryptedInvoiceId },
                      fetchPolicy: "no-cache",
                    });

                    if (data) {
                      setPackageOrderWithDetails((prev: any) => {
                        return {
                          ...prev,
                          orderDetails: [
                            ...prev.orderDetails.map((cart: any) => {
                              return cart.packageDetailsId === packageDetailsId
                                ? {
                                    ...cart,
                                    cartDetails: {
                                      ...cart.cartDetails,
                                      cardTitle: data?.saveStageOneCard.cardTitle,
                                      cartId: data?.saveStageOneCard.cartId,
                                      cartJsonData: data?.saveStageOneCard.cartJsonData,
                                      isPublished: data?.saveStageOneCard.isPublished,
                                      packageDetailsId: data?.saveStageOneCard.packageDetailsId,
                                    },
                                  }
                                : cart;
                            }),
                          ],
                        };
                      });

                      setMissingInfoCards(
                        filtersData.data?.getAvailableFiltersForPackageInfo.showMissingInfoFor,
                      );

                      successMsg("Saved");
                    }
                  }}>
                  {airportTransferType === "transfer1" ? (
                    <div className="inputs-container">
                      <div className="contacts-container">
                        <div className="input-container">
                          <label className={!isFieldsValid.transfer1.contactName1 ? "label-invalid" : ""}>
                            Contact Name
                          </label>
                          <div className="input-box">
                            <Input
                              className={!isFieldsValid.transfer1.contactName1 ? "input-invalid" : ""}
                              type="text"
                              placeholder="Contact Name"
                              name="contactName1"
                              invalid={!isFieldsValid.transfer1.contactName1}
                              onChange={(e) => {
                                setCardState((prev: any) => {
                                  return {
                                    ...prev,
                                    cartData: {
                                      ...prev.cartData,
                                      transfer1: {
                                        ...prev.cartData.transfer1,
                                        [e.target.name]: e.target.value,
                                      },
                                    },
                                  };
                                });
                              }}
                              value={cardState.cartData.transfer1.contactName1 || ""}
                            />
                            <span></span>
                          </div>
                        </div>
                        <div className="input-container">
                          <label className={!isFieldsValid.transfer1.contactNumber1 ? "label-invalid" : ""}>
                            Contact Number
                          </label>
                          <div className="input-box">
                          <PhoneInput
                            key={cardState?.cartData?.transfer1?.contactNumber1} 
                            className={!isFieldsValid?.transfer1?.contactNumber1 ? "input-invalid" : ""}                
                            placeholder="+1 234 5678 9999 9999"
                            mask="+9 999 9999 9999 9999"
                            secondaryMask="999 9999 9999 9999"
                            maskChar={null}
                            alwaysShowMask={false} 
                            value={cardState?.cartData?.transfer1.contactNumber1 || ''}
                            onChange={(value: any) => {
                              setCardState((prev: any) => {
                                return {
                                  ...prev,
                                  cartData: {
                                    ...prev.cartData,
                                    transfer1: {
                                      ...prev.cartData.transfer1,
                                      contactNumber1: value,
                                    },
                                  },
                                };
                              });
                            }}
                          />
                          {/* <InputMask
                            mask={masks.transfer1}
                            name={'contactNumber1'}
                            value={phoneValues.transfer1}
                            onChange={handlePhoneChange}
                            onBlur={handleBlur}
                            onFocus={handleFocus}
                            placeholder="+1 234 567 890"
                            className={!isFieldsValid.transfer1.contactNumber1 ? "input-invalid" : ""}
                          /> */}
                            <span></span>
                          </div>
                        </div>
                      </div>

                      <div className="divider" />
                      
                      <div style={{ marginBottom: '20px' }}>
                        <p style={{ fontSize: '20px', fontWeight: '400', fontFamily: "'Inter', sans-serif" }}>Departure Flight Details</p>
                        <p className="required-note" style={{ fontSize: '14px', fontWeight: '400', fontFamily: "'Inter', sans-serif" }}>* These fields are required.</p>
                      </div>
                      
                      <div className="input-container">
                        <label
                          className={!isFieldsValid.transfer1.departureFlightDate ? "label-invalid" : ""}
                        >
                          Departure Date
                        </label>
                        <div className="input-box">
                          <Input
                            style={{ color: '#71717A'}}
                            className={`custom-datetime-input ${!isFieldsValid.transfer1.departureFlightDate ? "input-invalid" : ""}`}
                            type="datetime-local"
                            placeholder="Departure Flight Date"
                            name="departureFlightDate"
                            invalid={!isFieldsValid.transfer1.departureFlightDate}
                            onChange={(e) => {
                              setCardState((prev: any) => {
                                return {
                                  ...prev,
                                  cartData: {
                                    ...prev.cartData,
                                    transfer1: {
                                      ...prev.cartData.transfer1,
                                      [e.target.name]: e.target.value,
                                    },
                                  },
                                };
                              });
                            }}
                            value={cardState.cartData.transfer1.departureFlightDate || ""}
                          />
                          <span></span>
                        </div>
                      </div>
                      
                      <div className="divider" />
                      
                      <div style={{ marginBottom: '20px' }}>
                        <p style={{ fontSize: '20px', fontWeight: '400', fontFamily: "'Inter', sans-serif" }}>Arrival Flight Details</p>
                        <p style={{ fontSize: '14px', fontWeight: '400', fontFamily: "'Inter', sans-serif" }} className="required-note">* These fields are required.</p>
                      </div>
                      <div className="input-container">
                        <label className={!isFieldsValid.transfer1.arrivalTime ? "label-invalid" : ""}>
                          Arrival Date
                        </label>
                        <div className="input-box">
                          <Input
                            style={{ color: '#71717A'}}
                            className={`custom-datetime-input ${!isFieldsValid.transfer1.arrivalTime ? "input-invalid" : ""}`}
                            type="datetime-local"
                            placeholder="Arrival Flight Date"
                            name="arrivalTime"
                            invalid={!isFieldsValid.transfer1.arrivalTime}
                            onChange={(e) => {
                              setCardState((prev: any) => {
                                return {
                                  ...prev,
                                  cartData: {
                                    ...prev.cartData,
                                    transfer1: {
                                      ...prev.cartData.transfer1,
                                      [e.target.name]: e.target.value,
                                    },
                                  },
                                };
                              });
                            }}
                            value={cardState.cartData.transfer1.arrivalTime || ''}
                          />
                          <span></span>
                        </div>
                      </div>
                      <div className="input-container">
                        <label className={!isFieldsValid.transfer1.airportName1 ? "label-invalid" : ""}>
                          Arrival Flight Airport
                        </label>
                        <div className="input-box">
                          {/* <Input
                            className={!isFieldsValid.transfer1.airportName1 ? "input-invalid" : ""}
                            type="text"
                            placeholder="Arrival Flight Airport"
                            name="airportName1"
                            invalid={!isFieldsValid.transfer1.airportName1}
                            onChange={(e) => {
                              setCardState((prev: any) => {
                                return {
                                  ...prev,
                                  cartData: {
                                    ...prev.cartData,
                                    transfer1: {
                                      ...prev.cartData.transfer1,
                                      [e.target.name]: e.target.value,
                                    },
                                  },
                                };
                              });
                            }}
                            value={cardState.cartData.transfer1.airportName1 || ""}
                          /> */}
                          <GooglePlaceAutocompleteInput
                            cardState={cardState}
                            setCardState={setCardState}
                            placeLabel={"airportName1"}
                            placeName={"airportPlaceName"}
                            placeValueName={"airportPlaceValue"}
                            placeLinkName={"airportPlaceLink"}
                            cardStateField={airportTransferType}
                          />
                          <span></span>
                        </div>
                      </div>{" "}
                      <div className="input-container">
                        <label
                          className={!isFieldsValid.transfer1.arrivalFlightAirline ? "label-invalid" : ""}
                        >
                          Arrival Flight Airline
                        </label>
                        <div className="input-box">
                          <Input
                            className={!isFieldsValid.transfer1.arrivalFlightAirline ? "input-invalid" : ""}
                            type="text"
                            placeholder="Arrival Flight Airline"
                            name="arrivalFlightAirline"
                            invalid={!isFieldsValid.transfer1.arrivalFlightAirline}
                            onChange={(e) => {
                              setCardState((prev: any) => {
                                return {
                                  ...prev,
                                  cartData: {
                                    ...prev.cartData,
                                    transfer1: {
                                      ...prev.cartData.transfer1,
                                      [e.target.name]: e.target.value,
                                    },
                                  },
                                };
                              });
                            }}
                            value={cardState.cartData.transfer1.arrivalFlightAirline || ""}
                          />
                          <span></span>
                        </div>
                      </div>{" "}
                      <div className="input-container">
                        <label className={!isFieldsValid.transfer1.flightNumber ? "label-invalid" : ""}>
                          Arrival Flight Number
                        </label>
                        <div className="input-box">
                          <Input
                            className={!isFieldsValid.transfer1.flightNumber ? "input-invalid" : ""}
                            type="text"
                            placeholder="Arrival Flight Number"
                            name="flightNumber"
                            invalid={!isFieldsValid.transfer1.flightNumber}
                            onChange={(e) => {
                              setCardState((prev: any) => {
                                return {
                                  ...prev,
                                  cartData: {
                                    ...prev.cartData,
                                    transfer1: {
                                      ...prev.cartData.transfer1,
                                      [e.target.name]: e.target.value,
                                    },
                                  },
                                };
                              });
                            }}
                            value={cardState.cartData.transfer1.flightNumber || ""}
                          />
                          <span></span>
                        </div>
                      </div>
                      
                      <div className="divider" />
                      
                      <div>
                        <p style={{ fontSize: '20px', fontWeight: '400', fontFamily: "'Inter', sans-serif" }}>Drop-off Address</p>
                        <p style={{ fontSize: '14px', fontWeight: '400', fontFamily: "'Inter', sans-serif" }} className="optional-note">Optional.</p>
                        <p style={{ fontSize: '12px', fontWeight: '400', fontFamily: "'Inter', sans-serif" }} className="optional-note">For your convenience, we’ll handle all transportation to your hotel, so no drop-off address is required.</p>
                      </div>
                      <div className="input-container">
                        {/* <label>Drop-off Address (if other than package hotel)</label> */}
                        <div className="input-box">
                          <GooglePlaceAutocompleteInput
                            cardState={cardState}
                            setCardState={setCardState}
                            placeName={"dropOffPlaceName"}
                            placeValueName={"dropOffPlaceValue"}
                            placeLinkName={"dropOffPlaceLink"}
                            cardStateField={airportTransferType}
                          />
                          <span></span>
                        </div>
                      </div>{" "}
                    </div>
                  ) : (
                    <div className="inputs-container">
                      <div className="contacts-container"> 
                        <div className="input-container">
                          <label className={!isFieldsValid.transfer2.contactName2 ? "label-invalid" : ""}>
                            Contact Name
                          </label>
                          <div className="input-box">
                            <Input
                              className={!isFieldsValid.transfer2.contactName2 ? "input-invalid" : ""}
                              type="text"
                              placeholder="Contact Name"
                              name="contactName2"
                              invalid={!isFieldsValid.transfer2.contactName2}
                              onChange={(e) => {
                                setCardState((prev: any) => {
                                  return {
                                    ...prev,
                                    cartData: {
                                      ...prev.cartData,
                                      transfer2: {
                                        ...prev.cartData.transfer2,
                                        [e.target.name]: e.target.value,
                                      },
                                    },
                                  };
                                });
                              }}
                              value={cardState.cartData.transfer2.contactName2 || ""}
                            />
                            <span></span>
                          </div>
                        </div>
                        <div className="input-container">
                          <label className={!isFieldsValid.transfer2.contactNumber2 ? "label-invalid" : ""}>
                            Contact Number
                          </label>
                          <div className="input-box">
                          <PhoneInput
                            key={cardState?.cartData?.transfer2?.contactNumber2} 
                            className={!isFieldsValid?.transfer2?.contactNumber2 ? "input-invalid" : ""}                
                            placeholder="+1 234 5678 9999 9999"
                            mask="+9 999 9999 9999 9999"
                            secondaryMask="999 9999 9999 9999"
                            maskChar={null}
                            alwaysShowMask={false} 
                            value={cardState?.cartData?.transfer2.contactNumber2 || ''}
                            onChange={(value: any) => {
                              setCardState((prev: any) => {
                                return {
                                  ...prev,
                                  cartData: {
                                    ...prev.cartData,
                                    transfer2: {
                                      ...prev.cartData.transfer2,
                                      contactNumber2: value,
                                    },
                                  },
                                };
                              });
                            }}
                          />
                          {/* <InputMask
                            mask={masks.transfer2}
                            name={'contactNumber2'}
                            value={phoneValues.transfer2}
                            onChange={handlePhoneChange}
                            onBlur={handleBlur}
                            onFocus={handleFocus}
                            placeholder="+1 234 567 890"
                            className={!isFieldsValid.transfer2.contactNumber2 ? "input-invalid" : ""}
                          /> */}
                            <span></span>
                          </div>
                        </div>
                      </div>
                      
                      <div className="divider" />
                      
                      <div style={{ marginBottom: '20px' }}>
                        <p style={{ fontSize: '20px', fontWeight: '400', fontFamily: "'Inter', sans-serif" }}>Departure Flight Details</p>
                        <p style={{ fontSize: '14px', fontWeight: '400', fontFamily: "'Inter', sans-serif" }} className="required-note">* These fields are required.</p>
                      </div>
                      
                      <div className="input-container">
                        <label style={{ marginBottom: '10px' }}>Pick-up Address (if other than package hotel)</label>
                        <p style={{ fontSize: '12px', fontWeight: '400', fontFamily: "'Inter', sans-serif", marginBottom: '10px' }} className="optional-note">For your convenience, we’ll handle all transportation to your hotel, so no pick-up address is required.</p>
                        <div className="input-box">
                          <GooglePlaceAutocompleteInput
                            cardState={cardState}
                            setCardState={setCardState}
                            placeLabel={"pickUpName"}
                            placeName={"pickUpPlaceName"}
                            placeValueName={"pickUpPlaceValue"}
                            placeLinkName={"pickUpPlaceLink"}
                            cardStateField={airportTransferType}
                          />
                          <span></span>
                        </div>
                      </div>
                      <div className="input-container">
                        <label
                          className={!isFieldsValid.transfer2.departureFlightDate ? "label-invalid" : ""}
                        >
                          Departure Date
                        </label>
                        <div className="input-box">
                          <Input
                            style={{ color: '#71717A'}}
                            className={`custom-datetime-input ${!isFieldsValid.transfer1.departureFlightDate ? "input-invalid" : ""}`}
                            type="datetime-local"
                            placeholder="Departure Flight Date"
                            name="departureFlightDate"
                            invalid={!isFieldsValid.transfer2.departureFlightDate}
                            onChange={(e) => {
                              setCardState((prev: any) => {
                                return {
                                  ...prev,
                                  cartData: {
                                    ...prev.cartData,
                                    transfer2: {
                                      ...prev.cartData.transfer2,
                                      [e.target.name]: e.target.value,
                                    },
                                  },
                                };
                              });
                            }}
                            value={cardState.cartData.transfer2.departureFlightDate || ""}
                          />
                          <span></span>
                        </div>
                      </div>{" "}
                      <div className="input-container">
                        <label className={!isFieldsValid.transfer2.airportName2 ? "label-invalid" : ""}>
                          Airport of Departure
                        </label>
                        <div className="input-box">
                          <GooglePlaceAutocompleteInput
                            cardState={cardState}
                            setCardState={setCardState}
                            placeLabel={"airportName2"}
                            placeName={"airportPlaceName"}
                            placeValueName={"airportPlaceValue"}
                            placeLinkName={"airportPlaceLink"}
                            cardStateField={airportTransferType}
                          />
                          {/* <Input
                            className={!isFieldsValid.transfer2.airportName2 ? "input-invalid" : ""}
                            type="text"
                            placeholder="Departure Flight Airport"
                            name="airportName2"
                            invalid={!isFieldsValid.transfer2.airportName2}
                            onChange={(e) => {
                              setCardState((prev: any) => {
                                return {
                                  ...prev,
                                  cartData: {
                                    ...prev.cartData,
                                    transfer2: {
                                      ...prev.cartData.transfer2,
                                      [e.target.name]: e.target.value,
                                    },
                                  },
                                };
                              });
                            }}
                            value={cardState.cartData.transfer2.airportName2 || ""}
                          /> */}
                          <span></span>
                        </div>
                      </div>{" "}
                      <div className="input-container">
                        <label
                          className={!isFieldsValid.transfer2.departureFlightAirline ? "label-invalid" : ""}
                        >
                          Airline
                        </label>
                        <div className="input-box">
                          <Input
                            className={
                              !isFieldsValid.transfer2.departureFlightAirline ? "input-invalid" : ""
                            }
                            type="text"
                            placeholder="Departure Flight Airline"
                            name="departureFlightAirline"
                            invalid={!isFieldsValid.transfer2.departureFlightAirline}
                            onChange={(e) => {
                              setCardState((prev: any) => {
                                return {
                                  ...prev,
                                  cartData: {
                                    ...prev.cartData,
                                    transfer2: {
                                      ...prev.cartData.transfer2,
                                      [e.target.name]: e.target.value,
                                    },
                                  },
                                };
                              });
                            }}
                            value={cardState.cartData.transfer2.departureFlightAirline || ""}
                          />
                          <span></span>
                        </div>
                      </div>{" "}
                      <div className="input-container">
                        <label
                          className={!isFieldsValid.transfer2.departureFlightNumber ? "label-invalid" : ""}
                        >
                          Departure Flight Number
                        </label>
                        <div className="input-box">
                          <Input
                            className={
                              !isFieldsValid.transfer2.departureFlightNumber ? "input-invalid" : ""
                            }
                            type="text"
                            placeholder="Departure Flight Number"
                            name="departureFlightNumber"
                            invalid={!isFieldsValid.transfer2.departureFlightNumber}
                            onChange={(e) => {
                              setCardState((prev: any) => {
                                return {
                                  ...prev,
                                  cartData: {
                                    ...prev.cartData,
                                    transfer2: {
                                      ...prev.cartData.transfer2,
                                      [e.target.name]: e.target.value,
                                    },
                                  },
                                };
                              });
                            }}
                            value={cardState.cartData.transfer2.departureFlightNumber || ""}
                          />
                          <span></span>
                        </div>
                      </div>{" "}
                    </div>
                  )}
                    
                  <div className="divider" />
                  
                  {airportTransferType === "transfer1" || airportTransferType === "transfer2" 
                    ? null 
                    : <div className="inputs-container">
                        <p style={{ fontSize: '16px', margin: 0 }}>
                          Additional Requests
                        </p>
                        <div className="input-container">
                          <div className="input-box">
                            <Input
                              type="textarea"
                              placeholder="Additional Requests"
                              name="additionalRequests"
                              onChange={(e) =>
                                setCardState((prev) => ({
                                  ...prev,
                                  cartData: { ...prev.cartData, [e.target.name]: e.target.value },
                                }))
                              }
                            />
                            <span></span>
                          </div>
                        </div>
                        <div className="input-container">
                          <div className="input-box">
                            <Input
                              type="textarea"
                              placeholder="Are you celebrating a special occasion?"
                              name="celebration"
                              onChange={(e) =>
                                setCardState((prev) => ({
                                  ...prev,
                                  cartData: { ...prev.cartData, [e.target.name]: e.target.value },
                                }))
                              }
                            />
                            <span></span>
                          </div>
                        </div>
                      </div>
                  }
                    
                  <div className="actions-container">
                    <CardDeclineBtn visible={!isServiceDeclined} handleClick={handleDeclineClick} />
                    <Button disabled={isSubmitBtnDisabled} className="btn-round btn-icon d-flex align-items-center save-btn" type="submit">
                      Save
                    </Button>
                  </div>
                </Form>
              </>
            </CardDetails>

            <div className="divider"></div> 

            <CardAction icon={helpIcon} text="Get help" 
              handleClick={() => {
                setIsModalOpen(true)
              }}
            />
          </>
        )}

        {!isPublished && !isMissingInfo &&
          <>
            <CardDetails
              isToggleExpanded={false}
              cardIcon={reservationIcon}
              cardTitle="Departure Reservation Details"
              cardSubtitle="Your transportation is guaranteed."
            >
              <>
                <CardGuaranteed text={isServiceDeclined && serviceDeclined !== 'UNDECLINED' ? "You’ve declined this service." : "Your airport transfer is guaranteed."} subtext={isServiceDeclined ? "If you need further assistance please use the Get Help button." : ""} />
                {!isServiceDeclined &&
                  <div className="actions-container">
                    <CardDeclineBtn visible={isServiceDeclined} handleClick={handleDeclineClick} />
                  </div>
                }
              </>
            </CardDetails>
            
            {/* <div className="divider"></div>
            
            <CardAction icon={jumpIcon} text="Jump to departure details" handleClick={() => alert('Jump to departure details')} /> */}
            
            <div className="divider"></div> 

            <CardAction icon={helpIcon} text="Get help" 
              handleClick={() => {
                setIsModalOpen(true)
              }}
            />
          </>
        }
        
        <TicketsPreviewModal
          isModalOpened={isModalOpened}
          setIsModalOpened={setIsModalOpened}
          selectedFile={selectedFile}
          fileType={fileType}
        />
      </>
    </StagedCardLayout>
  );
};

export default AirportTransferGroupCardNew;