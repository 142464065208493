import React, { useState } from "react";
import { Col, Row } from "reactstrap";
import { DEFAULT_CARD_CONFIG } from "../../utils/constants";
import TransferTypeButtons from "../Buttons/TransferTypeButtons";
import CartLayout from "./CardLayout";
import CardDetails from "../StagedCard/CardDetails/CardDetails";
import flagIcon from "../../assets/img/icons/cards/flag-icon.svg";
import copyIcon from "../../assets/img/icons/cards/copy-icon.svg";
import ticketIcon from "../../assets/img/icons/common/ticket-icon.svg";
import { TicketActions } from "../Modal/TicketActions";
import { TicketsPreviewModal } from "../Modal/TicketsPreviewModal";

import "./card.scss";


const Cart = ({
  cartSchemaConfig,
  cartData,
  airportTransferType,
  setAirportTransferType,
  vegasLimoTransferType,
  setVegasLimoTransferType,
  dataKey,
  isCartInfoOpenedId,
  handleOpenDetails,
  parsedCardDetails,
  cartInfoId,
  handleOpenLearnMore,
  isLearnMoreOpenedId,
  cardsWithTickets,
  ticketsForCard,
  packageDetailsId,
}: any) => {
  const [file, setFile] = useState<string | undefined>("");
  const [fileType, setFileType] = useState<string>("pdf");
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [isModalOpened, setIsModalOpened] = useState(false);
  const [selectedFile, setSelectedFile] = useState<string | undefined>("");

  const cardTicket = cardsWithTickets?.find((card: any) => card.packageDetailsId === packageDetailsId);

  if (dataKey === "Airport Transfer") {
    const parsedAirportTransfer = JSON.parse(cartData.cartDetails.cartJsonData);

    if (!cartData?.cartDetails.isPublished) {
      return (
        <CartLayout
          schemaConfig={DEFAULT_CARD_CONFIG[0]}
          parsedCardDetails={cartData}
          cartInfoId={cartInfoId}
          dataKey={dataKey}
          cardsWithTickets={cardsWithTickets}
          ticketsForCard={ticketsForCard}
          packageDetailsId={packageDetailsId}
        />
      );
    }
    return (
      <div key={cartInfoId}>
        {parsedAirportTransfer.transfer1 && parsedAirportTransfer.transfer2 ? (
          <Col>
            <TransferTypeButtons
              airportTransferType={airportTransferType}
              setAirportTransferType={setAirportTransferType}
              transfer1Date={parsedAirportTransfer.transfer1.date1}
              transfer2Date={parsedAirportTransfer.transfer2.date2}
            />
          </Col>
        ) : null}
        <div
          className={
            parsedAirportTransfer.transfer1 && parsedAirportTransfer.transfer2 ? "mt-4" : ""
          }
        >
          {cartSchemaConfig.map((transferSchemaConfig: any, id: number) => {
            const isCardDetailsOpened =
              transferSchemaConfig.cartInfo &&
              cartData.cartDetails &&
              isCartInfoOpenedId.includes(
                cartData.cartDetails?.packageDetailsId &&
                  (parsedAirportTransfer?.transferId || parsedCardDetails?.transferId),
              );

            if (
              transferSchemaConfig.hideIfEmpty &&
              !parsedCardDetails?.[transferSchemaConfig.name as keyof typeof cartData]
            ) {
              return;
            }

            if (isCardDetailsOpened) {
              return Object.entries(transferSchemaConfig).map(
                ([cardDetailsKey, cardDetailsConfig]: any, idx) => {
                  return (
                    <CartLayout
                      schemaConfig={cardDetailsConfig}
                      cartData={cartData}
                      isCartInfoOpenedId={isCartInfoOpenedId}
                      handleOpenDetails={handleOpenDetails}
                      parsedCardDetails={
                        parsedAirportTransfer.transfer1 && parsedAirportTransfer.transfer2
                          ? parsedCardDetails
                          : parsedAirportTransfer
                      }
                      cartInfoId={cartInfoId}
                      key={idx}
                      cardsWithTickets={cardsWithTickets}
                      ticketsForCard={ticketsForCard}
                      packageDetailsId={packageDetailsId}
                    />
                  );
                },
              );
            }

            if (transferSchemaConfig.skipInCart) {
              return;
            }

            return (
              <CartLayout
                schemaConfig={transferSchemaConfig}
                cartData={cartData}
                isCartInfoOpenedId={isCartInfoOpenedId}
                handleOpenDetails={handleOpenDetails}
                parsedCardDetails={
                  parsedAirportTransfer.transfer1 && parsedAirportTransfer.transfer2
                    ? parsedCardDetails
                    : parsedAirportTransfer
                }
                cartInfoId={cartInfoId}
                dataKey={dataKey}
                key={id}
                cardsWithTickets={cardsWithTickets}
                ticketsForCard={ticketsForCard}
                packageDetailsId={packageDetailsId}
              />
            );
          })}
        </div>
      </div>
    );
  }
  
  if (dataKey === "Vegas Limo Transfer") {
    const parsedVegasLimoTransfer = JSON.parse(cartData.cartDetails.cartJsonData);
    if (!cartData?.cartDetails.isPublished) {
      return (
        <CartLayout
          schemaConfig={DEFAULT_CARD_CONFIG[0]}
          parsedCardDetails={cartData}
          cartInfoId={cartInfoId}
          dataKey={dataKey}
          cardsWithTickets={cardsWithTickets}
          ticketsForCard={ticketsForCard}
          packageDetailsId={packageDetailsId}
        />
      );
    }
    return (
      <div key={cartInfoId}>
        {parsedVegasLimoTransfer.transfer1 && parsedVegasLimoTransfer.transfer2 ? (
          <Col>
            <TransferTypeButtons
              vegasLimoTransferType={vegasLimoTransferType}
              setVegasLimoTransferType={setVegasLimoTransferType}
              transfer1PickUp1={parsedVegasLimoTransfer.transfer1.pickUp1}
              transfer2PickUp2={parsedVegasLimoTransfer.transfer2.pickUp2}
            />
          </Col>
        ) : null}
        <div
          className={
            parsedVegasLimoTransfer.transfer1 && parsedVegasLimoTransfer.transfer2 ? "mt-4" : ""
          }
        >
          {cartSchemaConfig?.map((schemaConfig: any, id: number) => {
            const isCardDetailsOpened =
              schemaConfig.cartInfo &&
              cartData.cartDetails &&
              isCartInfoOpenedId.includes(
                cartData.cartDetails?.packageDetailsId &&
                  (parsedVegasLimoTransfer?.transferId || parsedCardDetails?.transferId),
              );

            if (
              schemaConfig.hideIfEmpty &&
              !parsedCardDetails?.[schemaConfig.name as keyof typeof cartData]
            ) {
              return;
            }

            if (isCardDetailsOpened) {
              return Object.entries(schemaConfig)?.map(
                ([cardDetailsKey, cardDetailsConfig]: any, idx) => {
                  return (
                    <CartLayout
                      schemaConfig={cardDetailsConfig}
                      cartData={cartData}
                      isCartInfoOpenedId={isCartInfoOpenedId}
                      handleOpenDetails={handleOpenDetails}
                      parsedCardDetails={
                        parsedVegasLimoTransfer.transfer1 && parsedVegasLimoTransfer.transfer2
                          ? parsedCardDetails
                          : parsedVegasLimoTransfer
                      }
                      cartInfoId={cartInfoId}
                      key={idx}
                      cardsWithTickets={cardsWithTickets}
                      ticketsForCard={ticketsForCard}
                      packageDetailsId={packageDetailsId}
                    />
                  );
                },
              );
            }

            if (schemaConfig.skipInCart) {
              return;
            }
            return (
              <CartLayout
                schemaConfig={schemaConfig}
                cartData={cartData}
                isCartInfoOpenedId={isCartInfoOpenedId}
                handleOpenDetails={handleOpenDetails}
                parsedCardDetails={
                  parsedVegasLimoTransfer.transfer1 && parsedVegasLimoTransfer.transfer2
                    ? parsedCardDetails
                    : parsedVegasLimoTransfer
                }
                cartInfoId={cartInfoId}
                dataKey={dataKey}
                key={id}
                cardsWithTickets={cardsWithTickets}
                ticketsForCard={ticketsForCard}
                packageDetailsId={packageDetailsId}
              />
            );
          })}
        </div>
      </div>
    );
  }

  if (
    dataKey === "MLB Card" ||
    dataKey === "NBA Card" ||
    dataKey === "NHL Card" ||
    dataKey === "Multi-day Gift Card"
  ) {
    const isMobile = window.innerWidth < 768;
    return (
      <div className="d-flex flex-wrap" style={{ marginTop: "-6px" }}>
        {parsedCardDetails.length &&
          parsedCardDetails?.map((item: any, id: number) => {
            return cartSchemaConfig?.map((field: any, idx: number) => {
              if (field.hideIfEmpty && !item?.[field.name as keyof typeof cartData]) {
                return null;
              }
              if (field.name.toLowerCase() === 'elink') {
                return (
                  <a
                  className=""
                  href={item['eLink']}
                  target="_blank"
                  rel="noreferrer"
                  style={{ color: "#007bff" }}
                  >
                    {item['eLink']}
                  </a>
                );
                
              }
              if (field.name === "description" && item.hasOwnProperty(field.name)) {
                return (
                  <CardDetails
                    cardIcon={copyIcon}
                    cardTitle={item[field.name]}
                    titleStyle={{ fontSize: '14px', fontWeight: '500' }}
                  />
                )
                // return (
                //   <CardDetails
                //     cardIcon={field.icon}
                //     cardTitle="Description"
                //     cardDescription={item[field.name]}
                //   />
                //   // <Col
                //   //   xs="12"
                //   //   style={{
                //   //     ...field.style,
                //   //     paddingLeft: field.icon ? "" : "1.25rem",
                //   //   }}
                //   //   className="d-flex p-0"
                //   //   key={idx}
                //   // >
                //   //   {field.icon ? (
                //   //     <div>
                //   //     <img src={field.icon} alt="copy-icon" 
                //   //       style={{
                //   //         width: "24px",
                //   //         textAlign: "center",
                //   //         marginRight: "0.5rem",
                //   //       }}
                //   //     />
                //   //     </div>
                //   //   ) : null}
                //   //   <span
                //   //     style={{ whiteSpace: "pre-line" }}
                //   //     dangerouslySetInnerHTML={{
                //   //       __html: item?.[field.name as any],
                //   //     }}
                //   //   ></span>
                //   // </Col>
                // );
              }
              
              if (field.name === "notes" && item.hasOwnProperty(field.name)) {
                return (
                  <div className="mt-2" style={{ width: '100%'}}>
                    <div className="divider" />
                    
                    <CardDetails
                      cardIcon={flagIcon}
                      cardTitle="Additional Notes"
                      cardDescription={item[field.name]}
                    />
                  </div>
                ) 
              }

              if (item.hasOwnProperty(field.name)) {
                return (
                  <Col
                    xs={!isMobile ? "6" : "12"}
                    style={{
                      ...field.style,
                      paddingLeft: "2rem",
                    }}
                    className="d-flex"
                    key={idx}
                  >
                    <span className="">
                      {field.label}: {item[field.name]}
                    </span>
                  </Col>
                );
              }
              
              return null;
            });
          })}
          
          {cardTicket &&
            <div style={{ width: "100%" }}>
              <div className="divider" />
              <CardDetails
                cardIcon={ticketIcon}
                cardTitle="Access Tickets now"
                children={
                  <div className="mt-4">
                    {ticketsForCard && ticketsForCard.length ? (
                        ticketsForCard.map((ticket: any, index: number) => (
                        <TicketActions
                          key={index}
                          ticket={ticket}
                          setFile={setFile}
                          setIsModalOpened={setIsModalOpened}
                          setSelectedFile={setSelectedFile}
                        />
                      ))
                    ) : (
                      <p>No tickets available</p>
                    )}
                  </div>
                }
              />
              <TicketsPreviewModal
                isModalOpened={isModalOpened}
                setIsModalOpened={setIsModalOpened}
                selectedFile={selectedFile}
                fileType={fileType}
              />
            </div>
          }
      </div>
    );
  }
  
  if (
    dataKey === "Fanatics Credit"
  ) {
    const isLink =  parsedCardDetails?.some((item: any) => 'eLink' in item);
    const isMobile = window.innerWidth < 768;
    return (
      <div className="d-flex flex-wrap" style={{ marginTop: "-6px" }}>
        {parsedCardDetails.length &&
          parsedCardDetails?.filter((item: any) => {
            if (isLink && 'eGiftCode' in item) {
              return false;
            }
            return true;
          }).map((item: any, id: number) => {
            return cartSchemaConfig?.map((field: any, idx: number) => {
              if (field.hideIfEmpty && !item?.[field.name as keyof typeof cartData]) {
                return null;
              }

              if (field.name === "description" && item.hasOwnProperty(field.name)) {
                const description = item?.[field.name as any]?.split("${or}");
                return (
                  <CardDetails
                    cardIcon={copyIcon}
                    titleStyle={{ fontSize: '14px', fontWeight: '500' }}
                    cardTitle={isLink ? description[1] : description[0]}
                    // cardDescription={item[field.name]}
                  />
                  // <Col
                  //   xs="12"
                  //   style={{
                  //     ...field.style,
                  //     paddingLeft: field.icon ? "" : "1.25rem",
                  //   }}
                  //   className="d-flex p-0"
                  //   key={idx}
                  // >
                  //   {field.icon ? (
                  //     <div>
                  //       <img src={field.icon} alt="copy-icon" 
                  //         style={{
                  //           width: "24px",
                  //           textAlign: "center",
                  //           marginRight: "0.5rem",
                  //         }}
                  //       />
                  //     </div>
                  //   ) : null}
                  //   <span
                  //     style={{ whiteSpace: "pre-line" }}
                  //     dangerouslySetInnerHTML={{
                  //       __html: isLink ? description[1] : description[0],
                  //     }}
                  //   ></span>
                  // </Col>
                );
              }
              
              if (field.name === "notes" && item.hasOwnProperty(field.name)) {
                return (
                  item[field.name] 
                  ? <div className="mt-2" style={{ width: '100%'}}>
                      <div className="divider" />
                      
                      <CardDetails
                        cardIcon={flagIcon}
                        cardTitle="Additional Notes"
                        cardDescription={item[field.name]}
                      />
                    </div> 
                  : null
                )
              }

              if (item.hasOwnProperty(field.name)) {
                return (
                  <Col
                    xs={!isMobile ? (isLink ? "12" : "6") : "12"}
                    style={{
                      ...field.style,
                      paddingLeft: "2rem",
                    }}
                    className="d-flex"
                    key={idx}
                  >
                    <span className="e-field">
                      {field.label}: {isLink ? <a href={item[field.name]} target="_blank" rel="noreferrer">{item['eLink']}</a> : item[field.name]}
                    </span>
                  </Col>
                );
              }
              
              return null;
            });
          })}

          {cardTicket &&
            <div style={{ width: "100%" }}>
              <div className="divider" />
              <CardDetails
                cardIcon={ticketIcon}
                cardTitle="Access Tickets now"
                children={
                  <div className="mt-4">
                    {ticketsForCard && ticketsForCard.length ? (
                        ticketsForCard.map((ticket: any, index: number) => (
                        <TicketActions
                          key={index}
                          ticket={ticket}
                          setFile={setFile}
                          setIsModalOpened={setIsModalOpened}
                          setSelectedFile={setSelectedFile}
                        />
                      ))
                    ) : (
                      <p>No tickets available</p>
                    )}
                  </div>
                }
              />
              <TicketsPreviewModal
                isModalOpened={isModalOpened}
                setIsModalOpened={setIsModalOpened}
                selectedFile={selectedFile}
                fileType={fileType}
              />
            </div>
          }
      </div>
    );
  }

  return (
    <div key={cartInfoId}>
      {cartSchemaConfig?.map((schemaConfig: any, id: number) => {
        const isCardDetailsOpened =
          schemaConfig.cartInfo &&
          cartData.cartDetails &&
          isCartInfoOpenedId.includes(cartData.cartDetails.packageDetailsId);

        const isLearnMoreOpened =
          schemaConfig.learnMore &&
          isLearnMoreOpenedId.includes(cartData.cartDetails.packageDetailsId);

        if (schemaConfig.skipInCart) {
          return;
        }

        if (
          schemaConfig.hideIfEmpty &&
          !parsedCardDetails?.[schemaConfig.name as keyof typeof cartData]
        ) {
          return;
        }

        if (
          schemaConfig.hideIfFieldWithNameHasValue &&
          parsedCardDetails?.[schemaConfig.hideIfFieldWithNameHasValue]
        ) {
          return;
        }

        if (isCardDetailsOpened) {
          return Object.entries(schemaConfig)?.map(
            ([cartDetailsKey, cartDetailsConfig]: any, idx) => {
              if (cartDetailsKey === "cartInfo" || cartDetailsKey === "checkPending") return;

              return (
                <CartLayout
                  schemaConfig={cartDetailsConfig}
                  cartData={cartData}
                  isCartInfoOpenedId={isCartInfoOpenedId}
                  handleOpenDetails={handleOpenDetails}
                  parsedCardDetails={parsedCardDetails}
                  cartInfoId={id}
                  key={idx}
                  cardsWithTickets={cardsWithTickets}
                  ticketsForCard={ticketsForCard}
                  packageDetailsId={packageDetailsId}
                />
              );
            },
          );
        }

        if (isLearnMoreOpened) {
          return (
            <CartLayout
              schemaConfig={schemaConfig}
              cartData={cartData}
              isCartInfoOpenedId={isCartInfoOpenedId}
              handleOpenDetails={handleOpenDetails}
              parsedCardDetails={parsedCardDetails}
              cartInfoId={id}
              handleOpenLearnMore={handleOpenLearnMore}
              isLearnMoreOpenedId={isLearnMoreOpenedId}
              isLearnMoreOpened={isLearnMoreOpened}
              key={id}
              cardsWithTickets={cardsWithTickets}
              ticketsForCard={ticketsForCard}
              packageDetailsId={packageDetailsId}
            />
            
          );
        }

        return (
          <CartLayout
            schemaConfig={schemaConfig}
            cartData={cartData}
            isCartInfoOpenedId={isCartInfoOpenedId}
            handleOpenDetails={handleOpenDetails}
            parsedCardDetails={parsedCardDetails}
            cartInfoId={id}
            handleOpenLearnMore={handleOpenLearnMore}
            isLearnMoreOpenedId={isLearnMoreOpenedId}
            key={id}
            cardsWithTickets={cardsWithTickets}
            ticketsForCard={ticketsForCard}
            packageDetailsId={packageDetailsId}
          />
        );
      })}
      {cardTicket &&
        <div style={{ width: "100%" }}>
          <div className="divider" />
          <CardDetails
            cardIcon={ticketIcon}
            cardTitle="Access Tickets now"
            children={
              <div className="mt-4">
                {ticketsForCard && ticketsForCard.length ? (
                  ticketsForCard.map((ticket: any, index: number) => (
                    <TicketActions
                      key={index}
                      ticket={ticket}
                      setFile={setFile}
                      setIsModalOpened={setIsModalOpened}
                      setSelectedFile={setSelectedFile}
                    />
                  ))
                ) : (
                  <p>No tickets available</p>
                )}
              </div>
            }
          />
          <TicketsPreviewModal
            isModalOpened={isModalOpened}
            setIsModalOpened={setIsModalOpened}
            selectedFile={selectedFile}
            fileType={fileType}
          />
        </div>
      }
    </div>
  );
};

export default Cart;
