import React, { useEffect, useState } from 'react';
import moment from 'moment';
import logoImg from '../../assets/img/images/elevate-logo.png';
import logoImgMobile from '../../assets/img/images/elevate-logo-mobile.png';
import cardBackgroundImg from '../../assets/img/images/card-background.png';
import getTicketsImg from '../../assets/img/images/modal-popup.png';
import datePickerIcon from '../../assets/img/icons/common/date-picker.svg';
import datePickerMobileIcon from '../../assets/img/icons/common/date-picker-mobile.svg';
import chevronMobile from '../../assets/img/icons/common/chevron-right-mobile.svg';
import GetInTouch from './GetInTouch';
import { Col, Row, Tooltip } from 'reactstrap';
import PageMenuSidebar from './PageMenuSidebar';

import './packageMenu.scss';

const PackageMenu = ({
  packageOrder,
  setIsModalOpened,
  activeCardsFilter, 
  setActiveCardsFilter, 
  availableFilters, 
  missingInfoCards
}: any) => {
  const [isOrdersOpen, setIsOrdersOpen] = useState(false);
  const [isMobileView, setIsMobileView] = useState(window.innerWidth <= 575);
  const [tooltipOpen, setTooltipOpen] = useState(false);

  const handleOrdersOpen = () => {
    setIsOrdersOpen((prev) => !prev);
  };

  const formatEventDate = (dateStr: any) => {
    if (!dateStr) return '';

    const [date, time] = dateStr.split(', ');
    const formattedTime = time.toUpperCase().replace(' PM', ' PM').replace(' AM', ' AM');
    return `${date} / ${formattedTime}`;
  };

  const address = packageOrder
    ? `${packageOrder?.venueCity}, ${packageOrder?.venue} (${packageOrder?.venueAddress})`
    : '';

  const eventDates =
    moment(packageOrder?.startDate, 'MMMM Do YYYY').toLocaleString() ===
    moment(packageOrder?.endDate, 'MMMM Do YYYY').toLocaleString()
      ? packageOrder?.startDate
      : packageOrder?.startDate + ' - ' + packageOrder?.endDate;

  const invoiceIdLabel = `Invoice ID: ${packageOrder?.invoiceId || ''}`;

  useEffect(() => {
    const handleResize = () => {
      setIsMobileView(window.innerWidth <= 575);
    };

    window.addEventListener('resize', handleResize);
    return () => {
      window.removeEventListener('resize', handleResize);
    };
  }, []);

  return (
    <div className="menu-container">
      <div className="logo-img-container">
        <img className="d-none d-sm-block logo-img" src={logoImg} alt="elevate-logo" />
        <img className="d-sm-none logo-img-mobile" src={logoImgMobile} alt="elevate-logo-mobile" />
      </div>
      <div
        className="default-font"
        style={{
          position: 'relative',
          borderTopLeftRadius: '20px',
          borderTopRightRadius: '20px',
          overflow: 'hidden',
        }}
      >
        <div
          style={{
            backgroundImage: `url(${cardBackgroundImg})`,
            backgroundSize: 'cover',
            backgroundPosition: 'center',
            position: 'absolute',
            top: 0,
            left: 0,
            right: 0,
            bottom: 0,
            zIndex: 1,
          }}
        ></div>
        <div
          style={{
            background: 'linear-gradient(180deg, rgba(0, 0, 0, 0) 53.33%, #000000 100%), #18181B',
            position: 'absolute',
            top: 0,
            left: 0,
            right: 0,
            bottom: 0,
            zIndex: 2,
            opacity: 0.5,
          }}
        ></div>
        <div className="mt-1 dates-container" style={{ position: 'relative', zIndex: 3 }}>
          <span className="dates-span">
            {packageOrder?.eventDate?.includes('3:30 am')
              ? packageOrder.eventDate.replace('3:30 am', 'Time TBD')
              : formatEventDate(packageOrder?.eventDate)}
          </span>
        </div>
        <div className="event-container" style={{ position: 'relative', zIndex: 3 }}>
          <span className="mb-0 event-span">{packageOrder?.eventName}</span>
        </div>
      </div>
      <div className="d-flex align-items-center justify-content-between py-2 pr-2 get-tickets-container">
        <div className="address-container">
          <span className="address-span">{address}</span>
        </div>
        <div className="get-ticket-img-container">
          {packageOrder?.orderDetails?.length ? (
            <img
              src={getTicketsImg}
              alt="get-ticket-img"
              className="get-ticket-img"
              onClick={() => {
                setIsModalOpened(true);
              }}
            />
          ) : (
            <div id="noTicketsImg">
              <img
                src={getTicketsImg}
                alt="get-ticket-img"
                className="get-ticket-img"
                style={{ cursor: 'not-allowed' }}
              />
              <Tooltip
                placement="top"
                isOpen={tooltipOpen}
                target="noTicketsImg"
                toggle={() => setTooltipOpen(!tooltipOpen)}
              >
                No tickets available
              </Tooltip>
            </div>
          )}
        </div>
      </div>
      {isMobileView ? (
        <div className="text-white">
          <Row className="py-2 pl-5 mr-1">
            <Col col="6" className="p-0 mr-1">
              <div className="section-span-container-mobile">
                <span className="section-span-mobile">
                  {packageOrder?.section} {packageOrder?.row}
                </span>
              </div>
              <div className="text-white">
                <span className="event-dates-mobile">{eventDates}</span>
              </div>
            </Col>
            <Col col="6" className="text-center p-0">
              <div>
                <span className="recipient-span-mobile">{packageOrder?.recipient}</span>
              </div>
            </Col>
          </Row>
          <div className="d-flex justify-content-center text-center" onClick={handleOrdersOpen}>
            <img className="pr-2" src={datePickerMobileIcon} alt="date-picker-mobile" />
            <span className="order-details-mobile-container pr-2">Order Details</span>
            <img src={chevronMobile} alt="chevron-mobile" />
          </div>
          {isOrdersOpen && (
            <>
              <PageMenuSidebar 
              isOpen={isOrdersOpen} 
              onClose={handleOrdersOpen}
              packageOrder={packageOrder}
              invoiceIdLabel={invoiceIdLabel}
              eventDates={eventDates}
              address={address}
              activeCardsFilter={activeCardsFilter}
              setActiveCardsFilter={setActiveCardsFilter}
              availableFilters={availableFilters}
              missingInfoCards={missingInfoCards}
              />
            </>
          )}
        </div>
      ) : (
        <div className={`mt-4 flex-column default-font`}>
          <div className="d-flex align-items-center">
            <img className="picker-icon" src={datePickerIcon} alt="picker-icon" />
            <span className="order-details-span">Order Details</span>
          </div>
          <div className="order-details-container">
            <div>
              <span className="section-span">
                {packageOrder?.section} {packageOrder?.row}
              </span>
            </div>
            <div className="text-white">
              <span className="event-dates">{eventDates}</span>
            </div>
            <div className="mt-3 mb-4 text-white font-sm">
              <div>
                <span className="recipient-span">{packageOrder?.recipient}</span>
              </div>
              <div>
                <span className="recipient-email-span">{packageOrder?.recipientEmail}</span>
              </div>
              <div className="d-flex flex-column">
                <span className="m-0 order-span">
                  Order ID:&nbsp;
                  {packageOrder?.externalPo || '-'}
                </span>
                <span className="m-0 invoice-span">{invoiceIdLabel}</span>
              </div>
            </div>
          </div>
          <GetInTouch />
        </div>
      )}
    </div>
  );
};

export default PackageMenu;
